<template>
    <div>
        <div>这是你的code:<span style="font-size: 18px;color: #F58F00;">{{code}}</span></div>
        <div>这是你的openID:<span style="font-size: 18px;color: #F58F00;">{{openid}}</span></div>
        <div>access_token:<span style="font-size: 18px;color: #F58F00;">{{access_token}}</span></div>
        <br>
        <div>这是你的微信名称:<span style="font-size: 18px;color: #F58F00;">{{nickname}}</span></div>
        <br>
        <div>这是你的头像</div>
        <div style="width: 200px;height: 200px;">
            <img style="width: 100%;height: 100%;" :src="headimgurl">
        </div>

    </div>
</template>

<script>
    export default {
        name: "wx_qrcode",
        data() {
            return{
                openid:"",
                access_token:"",
                nickname:"",
                headimgurl:""
            }
        },
        props:{
            code:{
                type:String,
                defaults() {
                    return "kong"
                }
            }
        },
        created() {


        },
        mounted() {
            var that = this;
            that.getOpenid();
        },
        methods:{
            getOpenid:function () {
                var that = this;
                that.newApi.getWxOpenid(that.code).then((res)=>{
                    that.openid = res.openid;
                    that.access_token = res.access_token;

                    if(res.openid != ""){
                        that.newApi.getWxUserinfo(res.access_token,res.openid).then((res)=>{
                            that.nickname = res.nickname
                            that.headimgurl = res.headimgurl;
                            console.log(res);
                        })
                    }

                })
            }
        }
    }
</script>

<style scoped>

</style>